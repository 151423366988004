<template>
  <div>
    <el-form :inline="true">
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="balancelist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#" width="80px"></el-table-column>
      <el-table-column prop="username" :label="$t('yhm')"></el-table-column>
      <el-table-column prop="code" :label="$t('jj.bianma')"></el-table-column>
      <el-table-column prop="days" :label="$t('jj.tianshu')"></el-table-column>
      <el-table-column prop="fee" :label="$t('jj.lilv')"></el-table-column>
      <el-table-column prop="total" :label="$t('jj.zongshu')">
      </el-table-column>
      <el-table-column prop="hasnum" :label="$t('jj.dqcjzs')"></el-table-column>
      <el-table-column prop="cha" :label="$t('jj.sjc')"></el-table-column>
      <el-table-column prop="oktime" :label="$t('jj.mbsj')"></el-table-column>
      <el-table-column prop="title" :label="$t('jj.lcjj')"></el-table-column>
      <el-table-column prop="status" :label="$t('jj.shzt')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '1'">通过</span>
          <span v-else-if="row.status === '2'">驳回</span>
          <span v-else>提交</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('caozuo')">
        <template slot-scope="{ row }">
          <el-button size="small" @click="shenhe(row.id)">{{
            $t('xxsh')
          }}</el-button>
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 编辑基金分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-row>
        <el-col :span="12">
          <b>{{ $t('jj.bianma') }}：</b> {{ editform.code }}
        </el-col>
        <el-col :span="12">
          <b>{{ $t('jj.qixian') }}：</b> {{ editform.days }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <b>{{ $t('jj.zongshu') }}：</b> {{ editform.total }}
        </el-col>
        <el-col :span="12">
          <b>{{ $t('jj.lilv') }}：</b> {{ editform.fee }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <b>{{ $t('jj.cjyh') }}：</b> {{ editform.username }}
        </el-col>
        <el-col :span="12">
          <b>{{ $t('jj.jjlx') }}：</b> {{ editform.title }}
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form :model="editform" label-width="120px" ref="editform">
        <el-form-item :label="$t('jj.zjts')">
          <el-input v-model="addday"></el-input>
          <el-alert :title="$t('jj.tsts')" type="error" :closable="false">
          </el-alert>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 审核基金发布弹窗区域 -->
    <el-dialog
      :title="$t('jj.lcjj')"
      :visible="shenhevisible"
      :before-close="shenheclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-row>
        <el-col :span="24">
          <b>{{ $t('jj.fbbm') }}：</b> {{ editform.code }}
        </el-col>
        <el-col :span="24">
          <b>{{ $t('jj.qixian') }}（{{ $t('jj.tianshu') }}）：</b>
          {{ editform.days }}
        </el-col>
        <el-col :span="24">
          <b>{{ $t('jj.fbzs') }}：</b> {{ editform.total }}
        </el-col>
        <el-col :span="24">
          <b>{{ $t('jj.lilv') }}（%）：</b> {{ editform.fee }}
        </el-col>
        <el-col :span="24">
          <b>{{ $t('jj.cjyh') }}：</b> {{ editform.username }}
        </el-col>
        <el-col :span="24">
          <b>{{ $t('jj.jjlx') }}：</b> {{ editform.title }}
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form :model="editform" label-width="180px" ref="editform">
        <el-form-item :label="$t('jj.xypj')">
          <el-input-number
            v-model="editform.xy_level"
            :min="1"
            :max="5"
            :label="$t('jj.xypj')"
          ></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('jj.tzpj')">
          <el-input-number
            v-model="editform.tz_level"
            :min="1"
            :max="5"
            :label="$t('jj.tzpj')"
          ></el-input-number>
        </el-form-item>
        <el-form-item :label="$t('jj.sfrz')">
          <el-radio-group v-model="editform.sf_auth">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('jj.jgrz')">
          <el-radio-group v-model="editform.jg_auth">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shenheclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="shenhesubmit(1)">{{
          $t('tongguo')
        }}</el-button>
        <el-button type="danger" @click="shenhesubmit(2)">{{
          $t('bohui')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        page: 1
      },
      balancelist: [],
      total: 0,
      loading: true,
      editdialogvisible: false,
      // 编辑分类数据对象
      editform: {},
      editid: '',
      addday: '',
      // 审核
      shenhevisible: false
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
  },
  methods: {
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/fund/publish', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(data.msg)
        } else {
          this.balancelist = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/fund/editpublish/id/' + id)
      this.editform = data.data
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post('/admin/fund/editpublish', {
          addday: this.addday,
          id: this.editid,
          action: 'day'
        })
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.addday = ''
            this.$message.success(this.getlang(data.msg))
            this.editclose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.editform.resetFields()
      this.editdialogvisible = false
    },
    async shenhe(id) {
      this.shenhevisible = true
      this.editid = id
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/fund/editpublish/id/' + id)
      this.editform = data.data
      loading.close()
    },
    // 编辑提交
    shenhesubmit(status) {
      this.$refs.editform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post('/admin/fund/editpublish', {
          status: status,
          id: this.editid,
          xy_level: this.editform.xy_level,
          tz_level: this.editform.tz_level,
          sf_auth: this.editform.sf_auth,
          jg_auth: this.editform.jg_auth,
          action: 'sh'
        })
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.addday = ''
            this.$message.success(this.getlang(data.msg))
            this.shenheclose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.shenhevisible = false
      })
    },
    // 关闭编辑弹窗
    shenheclose() {
      this.$refs.editform.resetFields()
      this.shenhevisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
.iconfont {
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-alert {
  margin-top: 15px;
}
.el-row {
  line-height: 30px;
}
</style>
